.yba-logo {
  width: 150px;
  animation-name: spin;
  animation-duration: 1s;
}

.csp-logo {
  width: 300px;
  animation-name: spin;
  animation-duration: 1s;
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-360deg);
  }
}

.forget-password-container {
  text-align: center;
  white-space: pre-wrap;
  background-color: white;
  position: absolute;
  max-width: 300px;
  width: 95%;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  box-shadow: #505050 3px 4px 20px 2px;
}
