.date-picker-container {
  text-align: center;
  padding: 16px;
  white-space: pre-wrap;
  background-color: white;
  position: absolute;
  max-width: 300px;
  width: 95%;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  box-shadow: #505050 3px 4px 20px 2px;
}

.date-picker-button-container {
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}
