@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    .print-page-break {
        page-break-after: always;
    }
}

@page {
    size: auto;
    margin: 5mm;
}
