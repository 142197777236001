.filter-tab {
  min-width: 50px;
  width: 100px;
}

.green-square {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  background: rgb(76, 175, 80);
  display: inline-block;
}

.green-square-text {
  display: inline-block;
  margin: 0;
  margin-left: 5px;
  margin-right: 16px;
  font-size: 12px;
}

.status-filter-toolbar {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.green-sqaure-container {
  display: flex;
  align-items: center;
}

.button-in-button-group {
  line-height: 16px;
}

.MuiTableRow-root {
  border-bottom: 2px solid #f5f5f5;
}

@media screen and (max-width: 600px) {
  .status-filter-container {
    padding: 12px;
  }
}
