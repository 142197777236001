.dashboard-filter-row:hover {
  cursor: pointer;
}

.work-orders-toolbar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  height: 64px;
}

.filter-area-container {
  display: block;
}

.filter-area-container-mobile {
  display: none;
}

.filter-popover > .MuiPopover-paper {
  height: 400px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .MuiInputBase-root.search-work-orders-input {
    width: 200px;
  }

  .filter-area-container {
    display: none;
  }

  .filter-area-container-mobile {
    display: block;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 960px) {
  .filter-grid-container {
    border-right: 2px solid #f5f5f5;
  }
}
