.accounting-report-header {
  display: flex;
  justify-content: space-between;
}

.accounting-report-steve {
  width: 50px;
  height: 50px;
  margin-top: -12px;
  margin-right: -12px;
  animation: bounce 1s;
}

.accounting-report-steve:hover {
  animation: rotate 1s infinite;
}

@keyframes bounce {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(1.5);
  }
  75% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
